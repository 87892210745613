<template>
  <v-col>
    <v-row>
      <v-col>
        <v-card class="pa-3">
          <v-card-title class="heading-1 darkestblue--text">
            Custom Reports
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!haveCustomReports">
      <v-col>
        <v-card class="pa-3">
          <v-card-text>
            <v-row>
              <v-col>
                <p class="px-4 pt-3">
                  No reports found.
                </p>

                <p class="px-4 pb-3">
                  <a
                    href="mailto:tech@adops.com?subject=iAdOps%20Custom%20Reports%20Issue"
                    target="_blank"
                  >Contact us</a>
                  if this is an unexpected response or would like to learn more about Custom Reports.
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card
          v-for="(customReport, customReportIndex) in customReports"
          :key="customReportIndex"
        >
          <v-card-text>
            <v-col>
              <v-row>
                <v-col
                  class="subtitle-2 font-weight-bold primary--text cursor-pointer"
                  :title="customReport.href"
                  @click="selectSearchItem(customReport)"
                >
                  <span>
                    {{ customReport.orgName }}: {{ customReport.name || 'Report Name Not Set' }}
                  </span>
                </v-col>
              </v-row>

              <v-row
                v-if="isInternalCustomReportUser"
                class="cursor-pointer"
                @click="toggleVisibilityOfUsers(customReport.docId)"
              >
                <v-col class="pt-0">
                  <v-row>
                    <v-col class="col-auto pr-2">
                      <span class="gray--text font-weight-medium">
                        {{ customReportUsersByBillingCode[customReport.docId].length }}
                        {{ customReportUsersByBillingCode[customReport.docId].length === 1 ? 'User' : 'Users' }}
                      </span>
                    </v-col>

                    <v-col class="col-auto pl-0">
                      <font-awesome-icon
                        :icon="['fas', showUsers[customReport.docId] ? 'chevron-up' : 'chevron-down']"
                        size="xs"
                        class="gray--text"
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="showUsers[customReport.docId]"
                    class="mt-0"
                  >
                    <v-col class="pa-0">
                      <v-list
                        dense
                        class="pt-0"
                      >
                        <v-list-item
                          v-for="(user, userIndex) in customReportUsersByBillingCode[customReport.docId]"
                          :key="userIndex"
                          class="px-3"
                        >
                          <v-list-item-content class="py-0">
                            <v-list-item-title>
                              <span class="midgray-text">
                                {{ user.docId }}
                              </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'CustomReportsList',
  data() {
    return {
      showUsers: {},
    }
  },
  computed: {
    ...mapAuthGetters([
      'isCustomReportUser',
      'isInternalUser',
    ]),
    ...mapToolsGetters([
      'currentCustomReports',
    ]),
    ...mapToolsState({
      searchResults: (state) => state.searchResults,
      selectedSearch: (state) => state.selectedSearch,
    }),
    customReports() {
      return this.currentCustomReports
    },
    haveCustomReports() {
      return this.customReports.length > 0
    },
    isInternalCustomReportUser() {
      return this.isCustomReportUser && this.isInternalUser
    },
    customReportUsers() {
      const { searchResults } = this
      const personEndpoint = searchResults && searchResults.endpoints.person

      if (!personEndpoint) {
        return []
      }

      const customReportUsers = personEndpoint?.items?.list || []

      return customReportUsers.filter((user) => user.hasCustomReports)
    },
    customReportUsersByBillingCode() {
      return this.customReports.reduce((customReportUsers, customReport) => {
        customReportUsers[customReport.billingCode] = this.getCustomReportUsersByBillingCode(customReport.billingCode)
        return customReportUsers
      }, {})
    },
  },
  methods: {
    getCustomReportUsersByBillingCode(billingCode) {
      return this.customReportUsers.filter((user) => user.isUserInternal || user.billingCodes?.includes(billingCode))
    },

    async selectSearchItem(selection) {
      await this.$store.dispatch('tools/selectSearchItem', selection)

      return this.$store.dispatch('tools/renderMainContent', this.$route)
    },

    toggleVisibilityOfUsers(docId) {
      this.$set(this.showUsers, docId, !this.showUsers[docId])
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.border-left {
  border-left: 3px solid $color-monochrome-offwhite;
}
</style>
